/* You can add global styles to this file, and also import other style files */
@use 'vars.scss';

html,
body {
  margin: 0;
  padding: 0;
  background-color: vars.$rlsilver;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  .rl-overlay {
    width: 100%;
    height: 100%;
    background: vars.$rlnavy;
    position: fixed;
    z-index: 8999;
    opacity: 0.9;
  }
}

::selection {
  background: vars.$rlreveal300;
}

::-moz-selection {
  background: vars.$rlreveal300;
}
